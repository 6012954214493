<template>
  <div class='main has-header has-footer bg-gray'>
    <mt-header fixed title="正在签约">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="tips">
      <img src="@assets/images/task/icon-sign-success.png" alt="">
      <h5 class="txt-black">
        正在努力签约中，成功后您将收到短信，<br> 
        并在我的任务里面，看到签约信息
      </h5>
    </div>
    <div class="footer-bt">
      <mt-button type="danger" size="large" @click="handleCheck">确定</mt-button>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: ['id'],
    data() {
      return {}
    },
    computed: {},
    watch: {},
    methods: {
      handleCheck() {
         this.$router.push('/taskCenter')
      }
    },
    created() {
  
    },
    mounted() {
  
    },
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
  }
</script>

<style lang='less' scoped>
  .tips {
    width: 100%;
    padding-top: 15%;
    text-align: center;
    img {
      margin: auto;
      width: 121px;
    }
  }
</style>